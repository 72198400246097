const Select = ({ current, setCurrent, options }) => (
  <div className="select-container">
    <select className="category-selection" value={current} onChange={(e) => { setCurrent(e.target.value); }}>
      {options.map((category) => (
        <option value={category} key={category}>{category}</option>
      ))}
    </select>
  </div>
)

export default Select;