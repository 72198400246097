import './App.css';
import Problem from './problem';
import Select from './header-select';
import { useSelector } from 'react-redux';
import {
  nextWord,
  setSubCategory,
  setCategory,
  getSubCategory,
  getSubCategories,
  getCategory,
  getCategories,
  getCurrent,
} from './features/wordSlice';
import { useBindDispatch } from './hooks/bind-dispatch';

function App() {
  const currentSubCategory = useSelector(getSubCategory);
  const currentSubCategories = useSelector(getSubCategories);
  const currentCategory = useSelector(getCategory);
  const currentCategories = useSelector(getCategories);
  const currentWord = useSelector(getCurrent);

  const _nextWord = useBindDispatch(nextWord);
  const _setSubCategory = useBindDispatch(setSubCategory);
  const _setCategory = useBindDispatch(setCategory);

  return (
    <div className="App">
      <header className="App-header">
        <Select
          current={currentCategory}
          setCurrent={_setCategory}
          options={currentCategories} />
        <Select
          current={currentSubCategory}
          setCurrent={_setSubCategory}
          options={currentSubCategories} />
      </header>
      <Problem
        className="App-section"
        category={currentCategory}
        word={currentWord}
        nextWord={_nextWord} />
    </div>
  );
}

export default App;
