import { useCallback, useLayoutEffect } from 'react';
import { Fireworks, useFireworks } from 'fireworks-js/dist/react'
import fireworksSettings from '../fireworks-settings'
import './problem.css';
import NounProblem from './noun-problem';




const ProblemCelebrationArea = ({ className, word, nextWord, category }) => {
  const { enabled, options, setEnabled } = useFireworks(fireworksSettings);

  const celebrate = useCallback(() => {
    setEnabled(true);
  }, [setEnabled])

  useLayoutEffect(() => {
    setEnabled(false);
  }, [word]); // eslint-disable-line react-hooks/exhaustive-deps

  const Component = {
    nouns: NounProblem
  }[category]

  return (
    <Fireworks
      style={{}}
      enabled={enabled}
      options={options}
      className={className}
    >
      {
      
      }
      <Component
        word={word[0]}
        meaning={word[1]}
        celebrate={celebrate}
        nextWord={nextWord}
      />
    </Fireworks>
  )
};

export default ProblemCelebrationArea;
