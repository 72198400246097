import { createSlice } from "@reduxjs/toolkit";
import { pickRandomItemFrom } from "../utils/random";

import nouns from '../data/nouns.json';

const data = {
  nouns
};

const categories = Object.keys(data);
const category = categories[0]; // TODO: smart decide which subcategory
const subCategories = Object.keys(data[category]);
const subCategory = subCategories[0]; // TODO: smart decide which subcategory
const current = pickRandomItemFrom(nouns[subCategory]);

const initialState = {
  categories,
  category,
  subCategories,
  subCategory,
  current,
  list: data[category][subCategory],
};

export const wordSlice = createSlice({
  name: 'word',
  initialState,
  reducers: {
    nextWord: (state) => {
      console.log(nouns[state.subCategory]);
      state.current = pickRandomItemFrom(data[category][state.subCategory]); // TODO: smart decide next word based on score for each word
    },
    setSubCategory: (state, { payload }) => {
      state.subCategory = payload;
      state.current = pickRandomItemFrom(data[category][state.subCategory]); // TODO: smart decide next word based on score for each word
    },
    setCategory: (state, { payload }) => {
      state.category = payload;
      state.subCategories = Object.keys(data[state.category]);
      state.subCategory = Object.keys(data[state.category])[0]; // TODO: smart decide sub-category based on what user was using before
    }
  }
});

export const { nextWord, setSubCategory, setCategory } = wordSlice.actions;

export default wordSlice.reducer;

export const getSubCategory = ({ word }) => word.subCategory;
export const getSubCategories = ({ word }) => word.subCategories;
export const getCategory = ({ word }) => word.category;
export const getCategories = ({ word }) => word.categories;
export const getCurrent = ({ word }) => word.current;
