import { useCallback, useLayoutEffect, useState } from "react";

const NounProblem = ({ word, meaning, celebrate, nextWord }) => {
  const [ article, displayWord ] = word.split(' ');
  const [ guess, setGuess ] = useState('');

  const submitGuess = useCallback((currentGuess) => {
    setGuess(currentGuess);
    if (currentGuess === article) {
      celebrate();
    }
  }, [setGuess, article, celebrate])

  useLayoutEffect(() => {
    setGuess('');
  }, [word]);
  return (
    <div style={{ zIndex: 3 }}>
      <div className={`noun problem-container${guess && guess !== article ? ' error' : ''}`}>
        <div className="problem">
          <span>{guess === article ? article : '___'}</span>
          <span className="display-word">{displayWord}</span>
        </div>
        <div className="solutions">
          <div className="solution">
            <button onClick={() => {submitGuess("der")}}>der</button>
          </div>
          <div className="solution">
            <button onClick={() => {submitGuess("die")}}>die</button>
          </div>
          <div className="solution">
            <button onClick={() => {submitGuess("das")}}>das</button>
          </div>
        </div>
      </div>
      <div className="correct" style={{ visibility: guess === article ? '' : 'hidden' }}>
        <div className="meaning">meaning: {meaning}</div>
        <button onClick={() => nextWord()}>Next word</button>
      </div>
    </div>
  );
};

export default NounProblem